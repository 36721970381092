.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .table-container {
    margin-top: 50px;
    margin-left: 100px;
    margin-right: 100px;
}


.textContainer {
    position: absolute;
    left: 200px;
    color: white;
    animation: slideFromRight 2s forwards;
  }
  @keyframes slideFromRight {
    from {
        left: -100%;
    }
    to {
        right: 0;
    }
  }
  .header1 {
    text-align: left;
    font-size: 30px;
    font-weight: 500;
  }
  .header2 {
    margin-top: 0px;
    text-align: left;
    font-size: 70px;
    font-weight: 800;
  }
  .header1,
  .header2 {
    margin: 0;
  }
  .header3 {
    text-align: left;
    font-size: 20px;
    font-weight: 800;
    margin-right: 20px;
  }
  .button {
    background-color: #633e98 !important;
    border-radius: 50px !important;
    width: 200px;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: #f3ec1a !important;
      color: #633e98;
      transform: scale(1.05);
    }
  }
  @media (max-width: 768px) {
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .textContainer {
        position: absolute;
        left: 20px;
        color: white;
      }
      .header1 {
        text-align: left;
        font-size: 15px;
        font-weight: 500;
      }
      .header2 {
        margin-top: 0px;
        text-align: left;
        font-size: 30px;
        font-weight: 800;
      }
      .header1,
      .header2 {
        margin: 0;
      }
      .header3 {
        text-align: left;
        font-size: 20px;
        font-weight: 800;
        margin-right: 20px;
      }
      .button {
        background-color: #633e98 !important;
        border-radius: 20px !important;
        width: 150px;
        height: 50px;
      }
}